import React, { Component } from 'react';

class PortfolioHeading extends Component {
  render() {
    return (
      
      <div className="portfolio-heading-wrap cell small-12">
        
        <div className="port-number">{this.props.number}</div>

        <div className="port-headline-wrap">
          <span className="port-headline">{this.props.headline}</span>
          <span className="port-year">{this.props.year}</span>
          <span className="port-role"><span className="role-hdr-txt">Role: </span>{this.props.role}</span>
        </div>
      </div>
      
    );
  }
}

export default PortfolioHeading;
