import React, { Component } from 'react';
import Heading1 from './Heading1';

class About extends Component {
  render() {
    return (
      <div className="about-wrap">
        
        <Heading1 text="ABOUT" />

        <div className="about-text-wrap">
      
         <div className="about-paragraph">
           I have <span className="bold-experience">8 years experience</span> in UI/UX design, prototyping, and front-end development for desktop and mobile devices.  I love seeing concepts through from wireframes to production.
         </div>

         <div className="about-paragraph">
           I also love to write, surf terribly, catch comedy shows, and explore different neighborhoods around Los Angeles.
         </div>

         <div className="about-work-together-txt">
          Want to get in touch?
         </div>

         <div className="work-together-link-wrap">
          <a href="mailto:jelombar@gmail.com?subject=Greetings" className="work-together-link">Send me an email </a>
         </div>

        </div>


      </div>
    );
  }
}

export default About;
