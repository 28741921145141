import React, { Component } from 'react';
import Heading1 from './Heading1';
import PortfolioHeading from './PortfolioHeading';
import PortfolioMainImage from './PortfolioMainImage';
import PortfolioDetails from './PortfolioDetails';
import PortfolioResults from './PortfolioResults';


class Portfolio extends Component {
  render() {
    return (
      <div className="portfolio-wrap">
        
        <Heading1 text="PORTFOLIO" />

        {/*************
          PROJECT 1 
          *************/}
        <div id="01" className="portfolio-project-wrap grid-x grid-margin-x">
          <PortfolioHeading 
          number="01."
          headline="Daily Buzz Platform Rebuild"
          year="2016"
          role="Wireframe, prototype, interaction design, visual design, front-end development."
          />

          <PortfolioMainImage 
          mainImageSrc="../images/portfolio/DB/port_DB_main_lrg.jpg"
          />
          
          <PortfolioDetails 
            overview="Overview"
            overviewText="Redesign and rebuild the Daily Buzz web application for improved user experience and responsiveness across devices."
            challenge="Challenge"
            challengeText="Give users a variety of different ways to visualize and interact with their data.  Create a app that can function as a workspace.  Maintain the usability and feature richness across tablet and mobile devices."
          />
          <PortfolioResults
          results="Results"
          image1Src="/images/portfolio/DB/port_DB_laptop"
          image1Caption="Main page with cart panel"
          image2Src="../images/portfolio/DB/port_DB_ipad"
          image2Caption="Campaigns page on tablet"
          image3Src="../images/portfolio/DB/port_DB_iphone-nav"
          image3Caption="Mobile nav menu"
          image4Src="../images/portfolio/DB/port_DB_iphone-campaigns"
          image4Caption="Mobile Campaigns page"
          galleryID="1"
          />        
        </div>



          {/*************
          PROJECT 2 
          *************/}
        <div id="02" className="portfolio-project-wrap grid-x grid-margin-x">
          <PortfolioHeading 
          number="02."
          headline="Daily Buzz Dashboard"
          year="2017"
          role="Visual design, interaction design, front-end development."
          />

          <PortfolioMainImage 
          mainImageSrc="../images/portfolio/DBDashboard/port_DBDashboard_main_lrg.jpg"
          />
          
          <PortfolioDetails 
            overview="Overview"
            overviewText="A flexible dashboard application that allows users to select a campaign & date range and see their campaign data come to life."
            challenge="Challenge"
            challengeText="Turn large amounts of complex data into an easy to digest and illustrative set of charts and graphs.  Integrate Kendo UI with Daily Buzz platform for data visualization.  Export all charts individually and as a whole in a variety of formats for easy sharing."
          />
          <PortfolioResults
          results="Results"
          image1Src="/images/portfolio/DBDashboard/port_DBDashboard_browser"
          image2Src="../images/portfolio/DBDashboard/port_DBDashboard_iphone-circle"
          image3Src="../images/portfolio/DBDashboard/port_DBDashboard_iphone-menu"
          image1Caption="Breaks over time graph with hover state and export options"
          image2Caption="Responsive charts with mobile interactions"
          image3Caption="Breaks over time and mobile menu"
          galleryID="2"
          />        
        </div>





        {/*************
          PROJECT 3 
          *************/}
        <div id="03" className="portfolio-project-wrap grid-x grid-margin-x">
          <PortfolioHeading 
          number="03."
          headline="ABC ‘For Your Consideration’ Site Redesign"
          year="2018"
          role="Visual design, front-end development."
          />

          <PortfolioMainImage 
          mainImageSrc="../images/portfolio/ABCFYC/port_ABCFYC_main_lrg.jpg"
          />
          
          <PortfolioDetails 
            overview="Overview"
            overviewText="A redesign of ABC’s ‘FYC’ site that showcases their current lineup of shows and allows Television Academy members to stream individual episodes."
            challenge="Challenge"
            challengeText="Incorporate individual design elements for each show while keeping an overall theme.  Create an accessible platform for Academy members to stream video content."
          />
          <PortfolioResults
          results="Results"
          image1Src="../images/portfolio/ABCFYC/port_ABCFYC_laptop"
          image2Src="../images/portfolio/ABCFYC/port_ABCFYC_browser-mf"
          image1Caption="Landing Page divided by studio"
          image2Caption="Campaign landing page with screener episodes"
          galleryID="3"
          />        
        </div>
        


        {/*************
          PROJECT 4
          *************/}
        <div id="04" className="portfolio-project-wrap grid-x grid-margin-x">
          <PortfolioHeading 
          number="04."
          headline="Daily Buzz Logo Design"
          year="2015"
          role="Concept, design."
          />

          <PortfolioMainImage 
          mainImageSrc="../images/portfolio/DBLogo/port_DBLogo_main_lrg.jpg"
          />
          
          <PortfolioDetails 
            overview="Overview"
            overviewText="Redesign the Daily Buzz logo for a cleaner, more modern appeal."
            challenge="Challenge"
            challengeText="Create simple yet striking design that encompasses the different products under the Daily Buzz umbrella."
          />
          {/* <PortfolioResults
          results="Results"
          image1Src="../images/portfolio/DBLogo/port_ABCFYC_laptop"
          image2Src="../images/portfolio/DBLogo/port_ABCFYC_browser-mf"
          image1Caption=""
          image2Caption=""
          galleryID="4"
          />       */}
        </div>



          {/*************
          PROJECT 5
          *************/}
        <div id="05" className="portfolio-project-wrap grid-x grid-margin-x">
          <PortfolioHeading 
          number="05."
          headline="Icon Design"
          year="2012 - Present"
          role="Concept, design, icon font."
          />

          <PortfolioMainImage 
          mainImageSrc="../images/portfolio/Icons/port_Icons_main_lrg2.jpg"
          />
          
          <PortfolioDetails 
            overview="Overview"
            overviewText="This is an ongoing project – designing a modern set of custom icons for use across MediaMax Online’s various applications."
            challenge="Challenge"
            challengeText="Turning often complex concepts into simple, easy to digest icons."
          />
          {/* <PortfolioResults
          results="Results"
          image1Src="../images/portfolio/DBLogo/port_ABCFYC_laptop"
          image2Src="../images/portfolio/DBLogo/port_ABCFYC_browser-mf"
          image1Caption=""
          image2Caption=""
          galleryID="5"
          />       */}
        </div>



          {/*************
          PROJECT 6
          *************/}
        <div id="06" className="portfolio-project-wrap grid-x grid-margin-x">
          <PortfolioHeading 
          number="06."
          headline="EPK.TV / Daily Buzz / MMD.TV Landing Pages"
          year="2015"
          role="Wireframes, visual design, interaction design, front-end development."
          />

          <PortfolioMainImage 
          mainImageSrc="../images/portfolio/Landings/port_Landings_main_lrg.jpg"
          />
          
          <PortfolioDetails 
            overview="Overview"
            overviewText="Creating a set of responsive marketing pages for MediaMax Online’s suite of products."
            challenge="Challenge"
            challengeText="Convey the most important points of each product in a clear and striking way, while keeping with a consistent theme for brand cohesion."
          />
          <PortfolioResults
          results="Results"
          image1Src="../images/portfolio/Landings/port_Landings_dbplatform"
          image2Src="../images/portfolio/Landings/port_Landings_dailybuzz"
          image3Src="../images/portfolio/Landings/port_Landings_epktv"
          image4Src="../images/portfolio/Landings/port_Landings_mmdtv"
          image1Caption="Daily Buzz Platform layout desktop"
          image2Caption="Daily Buzz mobile landing"
          image3Caption="EPK.TV mobile landing"
          image4Caption="MMD.TV mobile landing"
          galleryID="6"
          />      
        </div>

   
        

      </div>
      
      
      
    );
  }
}

export default Portfolio;
