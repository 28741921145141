import React, { Component } from 'react';

class SocialButton extends Component {
  render() {
    return (
      
      <a target="_blank" href={this.props.link} className={this.props.className + ' jl-social-btn'} rel="noopener noreferrer">
      </a>
        
    );
  }
}

export default SocialButton;
