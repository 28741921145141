import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

class Header extends Component {
  render() {
    let headerClass="hompage";
    if (window.location.pathname !== "/")
    {
      headerClass = "subpage";
    }

    return (      
      <header className={'jl-header-wrap ' + headerClass}>

      <Link className="jl-logo icon-jl-logo" to='/'></Link>

      <div className="header-blue-tint header-color-tint"></div>
        
        <nav className="jl-nav-wrap">
          <ul className="dropdown menu align-right jl-nav-menu" data-dropdown-menu>
            <li><NavLink exact to='/'>HOME</NavLink></li>
            <li><NavLink to='/portfolio'>PORTFOLIO</NavLink>
              <ul className="menu jl-nav-dropdown">
                <li><Link to='/portfolio#01'>01. &nbsp;Platform Rebuild</Link></li>
                <li><Link to='/portfolio#02'>02. Data Dashboard</Link></li>
                <li><Link to='/portfolio#03'>03. Site Redesign</Link></li>
                <li><Link to='/portfolio#04'>04. Logo Design</Link></li>
                <li><Link to='/portfolio#05'>05. Icon Design</Link></li>
                <li><Link to='/portfolio#06'>06. Landing Pages</Link></li>
              </ul>
            </li>
            <li><NavLink to='/about'>ABOUT</NavLink></li>
          </ul>
        </nav>

        <div className="subpage-hdr-color-bar"></div>

        <div className="splash-text-wrap">
        
            <div className="splash-name">Jeff Lombardo</div>

            <div className="splash-sentence">I’m a designer & front‑end developer delivering
clean, intuitive, and dynamic web experiences.</div>

            <Link className="see-portfolio-btn" to='/portfolio'>SEE MY PORTFOLIO</Link>

        </div>

        
      </header>
    );
  }


  
}

export default Header;
