import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Portfolio from './Portfolio';
import About from './About';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

class Main extends Component {
  render() {
    return (
      // <Route render={({ location }) => (
        <div>
          {/* <TransitionGroup>
           <CSSTransition
            key={window.location}
            timeout={188800}
            classNames='fade'
            > */}
             <Switch location={window.location}>
               <Route exact path='/'  component={Home} />
               <Route path='/portfolio' component={Portfolio} />
               <Route exact path='/about' component={About} />
              </Switch>
            {/* </CSSTransition>
          </TransitionGroup> */}
        </div>
      // )}> </Route>
      
    );
  }
}

export default Main;
