import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

// import 'normalize.css';
// import 'foundation-sites/dist/css/foundation.min.css';

// comes back undefined
// import 'jquery/dist/jquery.min.js'

// import './js/foundation.min.js';

// Foundation Imports
// import 'foundation-sites/js/foundation.core.js';
// import 'foundation-sites/js/foundation.dropdownMenu.js';
// import 'foundation-sites/js/foundation.util.keyboard.js';
// import 'foundation-sites/js/foundation.util.box.js';
// import 'foundation-sites/js/foundation.util.nest.js';


import './css/index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Router>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </Router>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
