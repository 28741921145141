import React, { Component } from 'react';
import '../css/foundation-custom.css';
import '../css/jl-app.scss';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import $ from 'jquery';
import '../css/jquery.fancybox.min.css';
// import '../foundation-sites/js/foundation.js';
// import '../foundation-sites/css/foundation.min.css';
import Foundation from 'foundation-sites';

window.jQuery = $;
window.foundation = Foundation;
require('@fancyapps/fancybox');





class App extends Component {
  componentDidMount() { 
    // add foundation to the document (page) only after the other page
    // elements have been loaded.
    $(document).foundation();
  } 
   
  render() {
    return (
      <div className="jl-app">
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;
