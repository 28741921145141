import React, { Component } from 'react';

class PortfolioMainImage extends Component {
  render() {
    
    return (
      <div className="portfolio-header-img-wrap cell small-12 large-6">
        <img alt="" className="port-main-img" src={this.props.mainImageSrc} />
      </div>

    );
  }
}

export default PortfolioMainImage;
