import React, { Component } from 'react';
import Heading1 from './Heading1';
import FeaturedWork from './FeaturedWork';

class Home extends Component {
  render() {
    return (
      <section className="jl-home-wrap">
        <Heading1 text="FEATURED WORK" />
        <FeaturedWork />

      </section>
        
      
    );
  }
}

export default Home;
