import React, { Component } from 'react';

class Heading1 extends Component {
  render() {
    return (
      <div className="heading1-wrap">
          <h1 className="heading1">
            {this.props.text}
          </h1>
          <span className="short-underline"></span>
        </div>
    );
  }
}

export default Heading1;
