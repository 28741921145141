import React, { Component } from 'react';

class PortfolioDetails extends Component {
  render() {
    return (
      <div className="portfolio-details-wrap cell small-12 large-6">

        <div className="port-header">{this.props.overview}</div>
        <div className="port-text">{this.props.overviewText}</div>
        
        <div className="port-header">{this.props.challenge}</div>
        <div className="port-text">{this.props.challengeText}</div>
      
      </div>
      
    );
  }
}

export default PortfolioDetails;
