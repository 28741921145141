import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class FeaturedWorkBlock extends Component {
  render() {


    return (
          <div className="featured-wrk-blk small-12 medium-6 cell">

          <div className="featured-work-spacing-wrap">

             <div className="featured-wrk-img" style={{backgroundImage: "url('" + this.props.imagePath + "')"}}>
             </div>

             <div className="featured-wrk-heading">
                {this.props.heading}
             </div>

             <div className="featured-wrk-text">
              {this.props.text} 
             </div>

             <Link to={this.props.link} className="featured-wrk-link">See it <span className="small-arrow icon-small-arrow"></span></Link>

           </div>
        
          </div>
      
    );
  }
}

export default FeaturedWorkBlock;
