import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import SocialButton from './SocialButton';

class Footer extends Component {
  render() {
    return (
      <footer className="">

        <div className="footer-color-bar"></div>

        <div className="jl-footer-wrap grid-x">

          <div className="jl-footer-content-wrap">

            <Link className="jl-footer-logo icon-jl-logo" to='/'></Link>

            <ul className="menu jl-footer-nav">
              <li><Link to='/'>HOME</Link></li>
              <li><Link to='/portfolio'>PORTFOLIO</Link></li>
              <li><Link to='/about'>ABOUT</Link></li>
            </ul>

            <SocialButton className="linkedin" link="https://www.linkedin.com/in/jeff-m-lombardo/" />
            {/* <SocialButton className="github" link="https://github.com/jelombar?tab=stars" /> */}


            <div className="jl-copyright">© Jeff Lombardo 2018</div>
            <div className="jl-site-built-with"><span className="site-built-text">Site built with</span>
              &nbsp;<a href="https://reactjs.org/" alt="React" className="footer-link react" target="_blank" rel="noopener noreferrer">React</a>,
              &nbsp;<a href="https://foundation.zurb.com/" alt="Foundation" className="footer-link foundation" target="_blank" rel="noopener noreferrer">Foundation</a>,
              &nbsp;<a href="https://www.w3.org/html/" alt="HTML5" className="footer-link html5" target="_blank" rel="noopener noreferrer">HTML5</a>,
              &nbsp;<a href="https://www.w3.org/Style/CSS/" alt="CSS3" className="footer-link css3" target="_blank" rel="noopener noreferrer">CSS3</a>
            </div>
        
        </div>
      
      
      </div>
        
        

      </footer>
    );
  }
}

export default Footer;
