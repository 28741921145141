import React, { Component } from 'react';
import FeaturedWorkBlock from './FeaturedWorkBlock';

class FeaturedWork extends Component {
  render() {
    return (
      <div className="featured-work-wrap">
        <div className="grid-x grid-margin-x">
          
          <FeaturedWorkBlock
          heading="Daily Buzz Platform Rebuild"
          text="Redesign and rebuild the Daily Buzz web application for improved user experience and responsiveness across devices."
          link="/portfolio#01"
          imagePath="../images/portfolio/DB/port_DB_main_lrg.jpg"
          />

          <FeaturedWorkBlock
          heading="‘For Your Consideration’ Site Redesign"
          text="A redesign of ABC’s ‘FYC’ site that showcases their current lineup of shows and allows Television Academy members to stream individual episodes."
          link="/portfolio#03"
          imagePath="../images/portfolio/ABCFYC/port_ABCFYC_main_lrg.jpg"
          />

        </div>
      
      </div>
      
    );
  }
}

export default FeaturedWork;
