import React, { Component } from 'react';

class PortfolioProjectResults extends Component {
  render() {
    
    // TO DO: Make common styles dynamic
    let commonBackgroundStyles = {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }

    let image1Background = {
      background: "url('" + this.props.image1Src + "')",
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }

    let image2Background = {
      background: "url('" + this.props.image2Src + "')",
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }

    let image3Background = {
      background: "url('" + this.props.image3Src + "')",
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }

    let image4Background = {
      background: "url('" + this.props.image4Src + "')",
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }

    // TO DO: ADD VARIABLE TO DETERMINE CLASSNAME 'medium-3' based off how many images there are
    // PASS IT IN AS A PROP like {this.props.columnClass}
    let numberOfImages = 0;
    let imageBackgroundNumber = 1;

    let imageBackgroundExists = this.props['image' + imageBackgroundNumber + 'Src'];

    while (imageBackgroundExists) {
      imageBackgroundNumber++;
      numberOfImages++;
      imageBackgroundExists = this.props['image' + imageBackgroundNumber + 'Src'];
    }

    let dynamicColumnNumber;

    if (numberOfImages > 3)
      dynamicColumnNumber = 3;
    else
      dynamicColumnNumber = 4;

    return (
      <div className="portfolio-results-wrap cell small-12">

        <div className="port-header">{this.props.results}</div>

        <div className="grid-x grid-margin-x results-images-wrap">
          
          {(this.props.image1Src) ? 
            <div className={"results-img-wrp small-12 medium-" + dynamicColumnNumber +  " cell"}> 
              <a data-fancybox={"gallery" + this.props.galleryID} className="results-img-anchor" href={this.props.image1Src + ".png"} data-caption={this.props.image1Caption}>
                <div className="results-img-sizing-wrap">
                  <img alt="" src={this.props.image1Src + "_thumb.jpg"}></img>
                </div>
              </a>
              <span className="results-caption">{this.props.image1Caption}</span>
            </div>
          : ''}

          {(this.props.image2Src) ? 
            <div className={"results-img-wrp small-12 medium-" + dynamicColumnNumber +  " cell"}> 
            <a data-fancybox={"gallery" + this.props.galleryID} className="results-img-anchor" href={this.props.image2Src + ".png"} data-caption={this.props.image2Caption}>
              <div className="results-img-sizing-wrap">
                <img alt="" src={this.props.image2Src + "_thumb.jpg"}></img>
              </div>
            </a>
            <span className="results-caption">{this.props.image2Caption}</span>
          </div>
          : ''}

          {(this.props.image3Src) ? 
            <div className={"results-img-wrp small-12 medium-" + dynamicColumnNumber +  " cell"}> 
            <a data-fancybox={"gallery" + this.props.galleryID} className="results-img-anchor" href={this.props.image3Src + ".png"} data-caption={this.props.image3Caption}>
              <div className="results-img-sizing-wrap">
                <img alt="" src={this.props.image3Src + "_thumb.jpg"}></img>
              </div>
            </a>
            <span className="results-caption">{this.props.image3Caption}</span>
          </div>
          : ''}

          {(this.props.image4Src) ? 
            <div className={"results-img-wrp small-12 medium-" + dynamicColumnNumber +  " cell"}> 
            <a data-fancybox={"gallery" + this.props.galleryID} className="results-img-anchor" href={this.props.image4Src + ".png"} data-caption={this.props.image4Caption}>
              <div className="results-img-sizing-wrap">
                <img alt="" src={this.props.image4Src + "_thumb.jpg"}></img>
              </div>
            </a>
            <span className="results-caption">{this.props.image4Caption}</span>
          </div>
          : ''}
          
        </div>
        
      
      </div>
      
    );
  }
}

export default PortfolioProjectResults;
